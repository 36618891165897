<template>
  <div id="user-container" v-if="accessibleRoutes.user">
    <div class="group-tabs">
      <v-btn-toggle v-model="toggle" mandatory>
        <router-link to="/tariffs/holiday-management">
          <v-btn :ripple="false"> HOLIDAY </v-btn>
        </router-link>
        <router-link to="/tariffs/time-of-use-management">
          <v-btn :ripple="false"> TIME OF USE </v-btn>
        </router-link>
        <router-link to="/tariffs/tariff-management">
          <v-btn :ripple="false"> TARIFF</v-btn>
        </router-link>
      </v-btn-toggle>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggle: 0,
    };
  },
  created() {
    if (this.$router.currentRoute.name == "TimeOfUseManagement") {
      this.toggle = 1;
    }
    if (this.$router.currentRoute.name == "TariffManagement") {
      this.toggle = 2;
    }
    this.getLocalTimeZone();
    if (localStorage.getItem('data_weather_one_call_storage')) {
      localStorage.removeItem('data_weather_one_call_storage');
    }
  },
  computed: {
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    },
    user: function () {
      return this.$store.getters.user;
    },
  },
  methods: {
    getLocalTimeZone () {
      this.$store.commit('setTimeZone', null);
      localStorage.removeItem('selectedTimeZone');
    },
  },
};
</script>
<style scoped>
 #user-container {
  height: 100%;
 }
</style>
